import api from '@/api'
import { createStore } from 'vuex'

const store = createStore({
  state() {
    return {
      currentDuration: 10,
      currentTab: -1,
      currentStationIndex: 0,
      tabs: [],
      timestamp: null,
      widget: null,
      selectedSkiStation: null,
      selectedWeatherStation: null,
      status: 'DEFAULT',
      mode: 'ALL'
    }
  },
  getters: {
    totalDuration: (state) => {
      let sum = 0
      const durations = state.tabs.filter((o) => o.duration)

      if (state.widget && durations.length === state.tabs.length) {
        state.tabs.forEach((o) => {
          sum += o.duration
        })
        return sum
      } else {
        return 0
      }
    }
  },
  mutations: {
    SET_CURRENT_STATION_BY_ID(state, payload) {
      state.currentStationIndex = state.widget.availableStations.findIndex((o) => o.id === payload)
    },
    SET_CURRENT_DURATION(state, payload) {
      state.currentDuration = payload
    },
    SET_CURRENT_TAB(state, payload) {
      state.currentTab = payload
    },
    NEXT_TAB(state) {
      if (state.currentTab === state.widget.menuFields.length - 1) {
        if (state.widget.availableStations.length > 1) {
          if (state.widget.availableStations[state.currentStationIndex + 1]) state.currentStationIndex++
          else state.currentStationIndex = 0
        } else {
          state.currentTab = 0
        }
      } else {
        state.currentTab++
      }
    },
    SET_WIDGET(state, payload) {
      if (payload.menuFields.findIndex((x) => x === state.mode) > -1) {
        console.log('Display single menu field')
        payload.menuFields = payload.menuFields.filter((x) => x === state.mode)
      }
      // if (!state.widget) state.currentStationIndex = payload.availableStations.findIndex((o) => o.id === payload.skiStationId)
      if (state.tabs.length !== payload.menuFields.length) state.tabs = payload.menuFields.map((o) => ({ name: o }))

      state.widget = payload
      state.currentTab = 0
      state.timestamp = Date.now()
    },
    SET_TAB(state, payload) {
      // state.tabs.find((o) => o.name === payload.name).duration = payload.duration
    },
    SET_STATUS(state, payload) {
      state.status = payload
    },
    SET_SKI_STATION(state, payload) {
      state.selectedSkiStation = state.widget.availableStations.find((o) => o.id === payload)
      localStorage.setItem('SELECTED_STATION', JSON.stringify(state.selectedSkiStation))
      state.currentStationIndex = state.widget.availableStations.findIndex((o) => o.id === payload)
    },
    SET_WEATHER_STATION(state, payload) {
      state.selectedWeatherStation = state.widget.weatherStations.find((o) => o.id === payload)
    },
    SET_MODE(state, payload) {
      state.mode = payload
    }
  },
  actions: {
    fetchWidget({ state, commit }, { id, selectedSkiStationId, mode }) {
      commit('SET_MODE', mode)
      return new Promise((resolve) => {
        const skiStationId = state.selectedSkiStation?.id || selectedSkiStationId
        const weatherStationId = state.selectedWeatherStation?.id
        api
          .fetchWidget({ id, skiStationId, weatherStationId })
          .then((response) => {
            const widget = response.data
            commit('SET_WIDGET', widget)
            commit('SET_STATUS', 'SUCCESS')
            commit('SET_CURRENT_STATION_BY_ID', skiStationId)

            resolve()
          })
          .catch((error) => {
            commit('SET_STATUS', 'ERROR')
            console.error('[API] fetchWidget', error)
          })
      })
    }
  }
})

export default store
